import React from "react";
import "./UpperNavBar.css";
import Twitter from "../../images/Twitter_icon.png";
import facebook_icon from "../../images/facebook1_icon.png";
import Youtube_icon from "../../images/Youtube1_icon.png";
import Phone_icon from "../../images/Phone1_icon.png";
import Gmail_icon from "../../images/Gmail1_icon.png";
import Clcok_icon from "../../images/Clock1_icon.png";
import Instagram_icon from "../../images/Instagram_icon.png";

const UpperNavBar = () =>{

    const handleGmailClick = () => {
        window.location.href = 'mailto:33koitdevidevta@gmail.com?subject=Subject&body=Body';
      };

    const handlePhoneClick = () => {
        window.location.href = 'tel:+91-8929123109';
      };

    return (
        <div className="nav-container">
            <div className="nav-cont1">
                <div className="forContacts"> <span onClick={handlePhoneClick}> <img src={Phone_icon} alt=""/> +91-8929123109 </span> </div>
                {/* <div className="forContacts"> <a href="#"> <img src={Phone_icon} alt=""/> +91-9999999999 </a> </div> */}
                <div className="forContacts" > <span  onClick={handleGmailClick}> <img src={Gmail_icon} alt="/"/> 33koitdevidevta@gmail.com </span> </div>
                <div className="forContacts"> <span > <img src={Clcok_icon} alt=""/> Mon-Fri: 10AM - 5PM </span> </div>
            </div>
            <div className="nav-cont2">
                <div className="social-sites-circle"> <a href="https://www.facebook.com/people/Devi-Devta/pfbid02h1VtYe8kDddKzoN7PTR2TMczB7KCgZ9ZpSF1crWtDR7wBUvY7oLiFoYmBEBAxeXul/?mibextid=ZbWKwL"> <img src={facebook_icon} alt=""/> </a> </div>
                <div className="social-sites-circle"> <a href="https://twitter.com/koti_33?t=3UgX3t45w2Syry0NxfkGnQ&s=09"> <img src={Twitter} alt=""/> </a> </div>
                <div className="social-sites-circle"> <a href="https://www.youtube.com/@33kotidevidevta1"> <img src={Youtube_icon} alt=""/> </a> </div>
                <div className="social-sites-circle"> <a href="https://www.instagram.com/_koti_33?igsh=ajQ3cmZ4ejI3ZXkw"> <img src={Instagram_icon} alt=""/> </a> </div>
            </div>
        </div>
    )
}



export default UpperNavBar;