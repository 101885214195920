import React from "react";
import "./Footer.css";
import logo from "../../images/Cow_logo2.jpeg";
import Twitter from "../../images/Twitter_icon.png";
import facebook_icon from "../../images/facebook1_icon.png";
import Youtube_icon from "../../images/Youtube1_icon.png";
import locationIcon from "../../images/Location_icons.png";
import PhoneIcon from "../../images/Phone1_icon.png";
import GmailIcon from "../../images/Gmail1_icon.png";
import Instagram_icon from "../../images/Instagram_icon.png";
import env from "react-dotenv"


const Footer = () => {

  const handleGmailClick = () => {
    window.location.href = 'mailto:33koitdevidevta@gmail.com?subject=Subject&body=Body';
  };

  const handlePhoneClick = () => {
    window.location.href = 'tel:+91-8929123109';
  };

  return (
    <div className="footer-container">
      <div className="footer1">
        <div className="footer-cont1">
          <div className="footer-logo">
            <img src={logo} alt="" />
          </div>
          <div className="footer-des">
            A Cow Care Center is a dedicated facility providing holistic care
            for cows. Offering veterinary services, nutritional support, and a
            comfortable environment, it ensures the well-being of cows.
          </div>
          <div className="footer-social">
                <div className="social-sites-circle"> <a href="https://www.facebook.com/people/Devi-Devta/pfbid02h1VtYe8kDddKzoN7PTR2TMczB7KCgZ9ZpSF1crWtDR7wBUvY7oLiFoYmBEBAxeXul/?mibextid=ZbWKwL"> <img src={facebook_icon} alt=""/> </a> </div>
                <div className="social-sites-circle"> <a href="https://twitter.com/koti_33?t=3UgX3t45w2Syry0NxfkGnQ&s=09"> <img src={Twitter} alt=""/> </a> </div>
                <div className="social-sites-circle"> <a href="https://www.youtube.com/@33kotidevidevta1"> <img src={Youtube_icon} alt=""/> </a> </div>
                <div className="social-sites-circle"> <a href="https://www.instagram.com/_koti_33?igsh=ajQ3cmZ4ejI3ZXkw"> <img src={Instagram_icon} alt=""/> </a> </div>
          </div>
        </div>
        <div className="footer-cont2">
            <div className="quick-links"> Quick-Links </div>
            <div className="actual-links">
                <span> <a href="/overview/activity">Activity</a> </span>
                <span> <a href="/involve/donate">Donate</a> </span>
                <span> <a href="/show/gallery">Gallery</a> </span>
                <span> <a href="/involve/adopt">Adopt</a> </span>
                <span> <a href="/overview/about-us">About-Us</a> </span>
                <span> <a href="/overview/contact-us">Contact-Us</a> </span>
            </div>
        </div>
        <div className="footer-cont3">
            <div className="address">
                Our Address
            </div>
            <div className="actual-contact">
                <div className="actual-address">
                    <div className="forLogo">
                    <img src={locationIcon}/>  
                    </div>
                    <div className="forAddress">
                        <span> Kamala </span>
                        <span> Baghpat, Uttar Pradesh </span>
                        <span> Pin Code: 250345 </span>
                    </div>
                </div>
                <div className="forPhone" onClick={handlePhoneClick}> <img src={PhoneIcon}/> +91-8929123109 </div>
                <div className="forGmail" onClick={handleGmailClick}> <img src={GmailIcon}/> 33koitdevidevta@gmail.com </div>
            </div>
        </div>
      </div>
      <div className="footer2">
        All Right Reserved for 33 Koti Devi Devta 2024
      </div>
    </div>
  );
};

export default Footer;
